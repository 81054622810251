import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  SelectInput,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import { createAdjustUnitPriceChoices, createPublishStateChoices } from "../components/choices";
import { StoragePeriodsField } from "../components/storage_periods_field";
import { ExistsSpecialConditionsField } from "../components/exists_special_conditions_field";
import { PrefecturesSelectField } from "../components/prefectures_select_field";
import { ShowToolbar } from "../components/ShowToolbar";

const OthersOfferFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput source="publish_status" choices={createPublishStateChoices()} />
    <TextInput source="offer_publication_period_name" />
    <TextInput source="brand_name" />
    <TextInput source="grade_name" />
    <TextInput source="annum_name" />
    <TextInput source="prefecture_name" />
    <SelectInput source="can_adjust_unit_price" choices={createAdjustUnitPriceChoices()} />
  </Filter>
);

export const OthersOfferList = (props: any) => (
  <List
    {...props}
    filters={<OthersOfferFilter />}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: "offer_publication_period_id", order: "DESC" }}
  >
    <CustomizableDatagrid rowClick="show">
      <SelectField source="publish_status" choices={createPublishStateChoices()} sortable={false} />
      <ReferenceField source="offer_publication_period_id" reference="offer_publication_periods" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateField source="publish_start_date" locales={"ja"} sortable={false} />
      <DateField source="publish_end_date" locales={"ja"} sortable={false} />
      <ReferenceField source="brand_id" reference="brands" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="grade_id" reference="grades" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="annum_id" reference="annums" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <PrefecturesSelectField source="prefecture_ids" reference="prefectures" />
      <ReferenceField source="region_id" reference="regions" link={false} sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="unit_price" sortable={false} />
      <NumberField source="total_trading_units" sortable={false} />
      <SelectField source="can_adjust_unit_price" choices={createAdjustUnitPriceChoices()} sortable={false} />
      <StoragePeriodsField source="storage_period_ids" sortable={false} />
      <ExistsSpecialConditionsField source="special_condition_ids" sortable={false} />
    </CustomizableDatagrid>
  </List>
);

export const OthersOfferShow = (props: any) => (
  <Show {...props} title="resources.others_offers.name" actions={<ShowToolbar listPath="/others_offers" />}>
    <SimpleShowLayout>
      <SelectField source="publish_status" choices={createPublishStateChoices()} />
      <ReferenceField source="offer_publication_period_id" reference="offer_publication_periods" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateField source="publish_start_date" locales={"ja"} />
      <DateField source="publish_end_date" locales={"ja"} />
      <ReferenceField source="brand_id" reference="brands" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="grade_id" reference="grades" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="annum_id" reference="annums" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField source="prefecture_ids" reference="prefectures">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField source="region_id" reference="regions" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="unit_price" />
      <NumberField source="lowest_trading_units" />
      <NumberField source="highest_trading_units" />
      <NumberField source="total_trading_units" />
      <ReferenceArrayField source="packaging_form_ids" reference="packaging_forms">
        <Datagrid>
          <TextField source="name" sortable={false} label="resources.others_offers.fields.packaging_form_ids.name" />
          <NumberField
            source="deal_amount"
            sortable={false}
            label="resources.others_offers.fields.packaging_form_ids.deal_amount"
          />
        </Datagrid>
      </ReferenceArrayField>
      <SelectField source="can_adjust_unit_price" choices={createAdjustUnitPriceChoices()} />
      <ReferenceArrayField source="storage_period_ids" reference="storage_periods" linkType={false}>
        <Datagrid>
          <TextField label="resources.storage_periods.fields.name" source="name" />
          <NumberField
            label="resources.storage_periods.fields.storage_period_in_days"
            source="storage_period_in_days"
          />
          <NumberField label="resources.storage_periods.fields.bonus_in_yen" source="bonus_in_yen" />
        </Datagrid>
      </ReferenceArrayField>
      <ArrayField source="grade_penalties">
        <Datagrid>
          <ReferenceField source="grade_id" reference="grades" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="penalty_in_yen" emptyText={"受け取らない"} />
        </Datagrid>
      </ArrayField>
      <ReferenceArrayField
        source="special_condition_ids"
        reference="special_conditions"
        className="array-field-that-potential-empty"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);
