import React from "react";
import { useRedirect, useTranslate } from "react-admin";
import { useLocation } from "react-router";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

import { getAnotherContractResource, getContractType, pluckResource } from "../utils/route";

const useStyles = makeStyles({
  active: {
    backgroundColor: "#ffffff !important",
    border: "1px solid #358205 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    zIndex: 1,
  },
  inactive: {
    backgroundColor: "#358205 !important",
    border: "1px solid #358205 !important",
    color: "rgba(255, 255, 255, 0.87) !important",
    zIndex: 0,
    "&:hover": {
      backgroundColor: "#ffffff !important",
      border: "1px solid #358205 !important",
      color: "rgba(0, 0, 0, 0.87) !important",
    },
  },
  disabled: {
    backgroundColor: "#A3A3A3 !important",
    border: "1px solid #A3A3A3 !important",
    color: "rgba(255, 255, 255, 0.87) !important",
    zIndex: 0,
  },
});

export const ToggleContractTypeButton = () => {
  const redirect = useRedirect();
  const classes = useStyles();
  const translate = useTranslate();
  const location = useLocation();
  const { pathname } = location;

  const resource = pluckResource(pathname);
  if (resource === null) {
    return null; // 基本はありえない
  }

  const contractType = getContractType(resource);
  if (contractType === null) {
    return null; // 共通のリソースの場合
  }

  const convertTypeTo = contractType === "advance_contract" ? "spot_contract" : "advance_contract";

  // オファーリクエストなど片方しか定義されていない項目の場合にnullになる
  const anotherResource = getAnotherContractResource(resource, convertTypeTo);

  const onClick = () => {
    if (anotherResource === null) {
      return;
    }
    redirect(`/${anotherResource}`);
  };
  const disableButton = anotherResource === null;

  switch (contractType) {
    case "advance_contract": {
      return (
        <ButtonGroup disableElevation>
          <Button variant="contained" className={classes.active} disabled>
            {translate("ui.contract_type.advance_contract")}
          </Button>
          <Button
            variant="contained"
            className={classnames({ [classes.inactive]: true, [classes.disabled]: disableButton })}
            onClick={onClick}
            disabled={disableButton}
          >
            {translate("ui.contract_type.spot_contract")}
          </Button>
        </ButtonGroup>
      );
    }
    case "spot_contract": {
      return (
        <ButtonGroup disableElevation>
          <Button
            variant="contained"
            className={classnames({ [classes.inactive]: true, [classes.disabled]: disableButton })}
            onClick={onClick}
            disabled={disableButton}
          >
            {translate("ui.contract_type.advance_contract")}
          </Button>
          <Button variant="contained" className={classes.active} disabled>
            {translate("ui.contract_type.spot_contract")}
          </Button>
        </ButtonGroup>
      );
    }
  }
};

export default ToggleContractTypeButton;
