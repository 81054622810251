import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  SelectInput,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  downloadCSV,
  useShowController,
  useTranslate,
} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import jsonExport from "jsonexport/dist";

import { SpotContractPaymentShow } from "./spot_contract_payment";
import { AddressField } from "../components/AddressField";
import { Alert } from "../components/Alert";
import {
  createDeliveryStatusChoices,
  createDeliveryTypeChoices,
  createHasProducerReviewed,
  createPaymentStatuses,
} from "../components/choices";
import CreateRelatedResourceButton from "../components/CreateRelatedResourceButton";
import { SpotContractDealShowActions } from "../components/SpotContractDealShowActions";
import { ExistsSpecialConditionsField } from "../components/exists_special_conditions_field";
import { ProducerAgreementStatusField } from "../components/producer_agreement_status_field";
import { WholesalerAgreementStatusField } from "../components/wholesaler_agreement_status";
import { useSpotContractAmend } from "../hooks/useAmend";
import { DeleteDeliveryButton, EditDeliveryButton, InspectDeliveryButton } from "../components/SpotContractAmendButton";
import { AttachmentFileField } from "../components/AttachmentFile";
import { getDateString, getFormatNumeric, getFormatRateString } from "../utils/locale";
import { SpotContractIndividualDeliveryShow } from "./spot_contract_individual_deliveries";
import { HandoverAtField } from "../components/HandoverAtField";
import { ReceiptTimingField } from "../components/ReceiptTimingField";
import { useSpotContractUnassignedDeliveryContents } from "../hooks/useUnassignedDeliveryContents";
import { InvoiceRegistrationStatusField } from "../components/InvoiceRegistrationStatusField";

const statuses = ["closed", "provisional", "not_closed", "done", "cancelled"];
const createStatusChoices = () =>
  statuses.map((status) => ({
    id: status,
    name: `resources.deals.fields.statuses.${status}`,
  }));

const agreementStatuses = ["waiting", "agreement", "disagreement"];
const createAgreementStatusChoices = () =>
  agreementStatuses.map((status) => ({
    id: status,
    name: `commons.agreement_statuses.${status}`,
  }));

const amendStatuses = ["waiting_for_delivery_creation", "waiting_for_after_delivery_inspection", "waiting_for_payment"];
const createAmendStatusChoices = () =>
  amendStatuses.map((status) => ({
    id: status,
    name: `commons.amend_statuses.${status}`,
  }));

const SpotContractDealFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="offer_id" />
    <SelectInput source="status" choices={createStatusChoices()} />
    <SelectInput source="amend_status" choices={createAmendStatusChoices()} label="ui.deals.amend_status" />
    <SelectInput source="producer_agreement_status" choices={createAgreementStatusChoices()} />
    <SelectInput source="wholesaler_agreement_status" choices={createAgreementStatusChoices()} />
    <TextInput source="producer_company_name" />
    <TextInput source="annum_name" />
    <TextInput source="prefecture_name" />
    <TextInput source="region_name" />
    <TextInput source="brand_name" />
    <TextInput source="grade_name" />
  </Filter>
);

const export_headers = [
  "id",
  "offer_id",
  "status",
  "producer_agreement_status",
  "wholesaler_agreement_status",
  "agreement_deadline",
  "decision_date",
  "producer.company_name",
  "annum.name",
  "prefecture.name",
  "region.name",
  "brand.name",
  "grade.name",
  "packaging_form.name",
  "trading_units",
  "trading_sacks",
  "unit_price",
  "trading_volume_in_yen",
  "storage_period.name",
  "storage_period.bonus_in_yen",
  "grade_penalties",
  "special_conditions",
  "special_conditions_memo",
  "handover_possible_date",
];

const exporter = (translate: any) => {
  const renamed_headers = export_headers.map((name) => translate(`resources.spot_contract_deals.fields.${name}`));

  return (deals: any) => {
    const dealsForExport = deals.map((deal: any) => {
      deal.region = deal.region ? deal.region : {};
      deal.amend = deal.amend ? deal.amend : {};

      const partial_deal = export_headers.reduce((result: any, prop) => {
        if (prop.includes(".")) {
          const [object, property] = prop.split(".");
          if (result[object] === undefined) {
            result[object] = {};
          }

          result[object][property] = deal[object][property];
        } else {
          result[prop] = deal[prop];
        }

        return result;
      }, {});

      partial_deal.status = translate(`resources.spot_contract_deals.fields.statuses.${deal.status}`);
      partial_deal.decision_date = getDateString(deal.decision_date);
      partial_deal.producer_agreement_status = translate(
        `commons.agreement_statuses.${deal.producer_agreement_status}`
      );
      partial_deal.wholesaler_agreement_status = translate(
        `commons.agreement_statuses.${deal.wholesaler_agreement_status}`
      );
      partial_deal.agreement_deadline = getDateString(deal.agreement_deadline);
      partial_deal.grade_penalties =
        deal.grade_penalties.length > 0
          ? deal.grade_penalties
              .map(
                (item: any) =>
                  item.grade.name + "：" + (item.penalty_in_yen === null ? "受け取らない" : item.penalty_in_yen + "円")
              )
              .join("、")
          : "";
      partial_deal.special_conditions =
        deal.special_conditions.length > 0
          ? deal.special_conditions.map((item: any) => item.display_text).join("、")
          : "";
      partial_deal.handover_possible_date = getDateString(deal.handover_possible_date);

      return partial_deal;
    });

    jsonExport(
      dealsForExport,
      {
        headers: export_headers,
        rename: renamed_headers,
      },
      (err, csv) => {
        const buffer = new Buffer("\ufeff" + csv);
        downloadCSV(buffer, "現物契約_取引案件");
      }
    );
  };
};

const DEFAULT_COLUMNS = [
  "id",
  "status",
  "producer_agreement_status",
  "wholesaler_agreement_status",
  "agreement_deadline",
  "decision_date",
  "producer.company_name",
  "annum.name",
  "prefecture.name",
  "region.name",
  "brand.name",
  "grade.name",
  "packaging_form.name",
  "trading_units",
  "trading_sacks",
  "unit_price",
  "special_condition_ids",
];

export const SpotContractDealList = (props: any) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      filters={<SpotContractDealFilter />}
      sort={{ field: "id", order: "DESC" }}
      bulkActionButtons={false}
      exporter={exporter(translate)}
    >
      <CustomizableDatagrid rowClick="show" defaultColumns={DEFAULT_COLUMNS}>
        <TextField source="id" />
        <TextField source="offer_id" />
        <SelectField source="status" choices={createStatusChoices()} sortable={false} />
        <ProducerAgreementStatusField source="producer_agreement_status" sortable={false} />
        <WholesalerAgreementStatusField source="wholesaler_agreement_status" sortable={false} />
        <DateField source="agreement_deadline" locales={"ja"} />
        <DateField source="decision_date" locales={"ja"} />
        <TextField source="producer.company_name" sortable={false} />
        <TextField source="annum.name" sortable={false} />
        <TextField source="prefecture.name" sortable={false} />
        <TextField source="region.name" sortable={false} />
        <TextField source="brand.name" sortable={false} />
        <TextField source="grade.name" sortable={false} />
        <TextField source="packaging_form.name" sortable={false} />
        <NumberField source="trading_units" sortable={false} />
        <NumberField source="trading_sacks" sortable={false} />
        <NumberField source="unit_price" sortable={false} />
        <NumberField source="trading_volume_in_yen" sortable={false} />
        <ExistsSpecialConditionsField source="special_condition_ids" sortable={false} />
      </CustomizableDatagrid>
    </List>
  );
};

export const SpotContractDealShow = (props: any) => {
  const { permissions } = props;
  const { record } = useShowController(props);
  const isClosedOrDone = record?.status === "closed" || record?.status === "done";
  const amendId = isClosedOrDone ? record.id : undefined;
  const [loadingAmend, amend] = useSpotContractAmend(amendId);
  const [loadingUnassignedDeliveryContents, unassignedDeliveryContents] = useSpotContractUnassignedDeliveryContents(
    amendId
  );
  const translate = useTranslate();
  if (!record || record.status === undefined || loadingAmend || loadingUnassignedDeliveryContents) {
    return null;
  }

  const isDeliveryCreationStep = amend?.current_step_buyer === "waiting_for_delivery_creation";
  const isDeliveryInspectionStep = amend?.current_step_buyer === "waiting_for_after_delivery_inspection";
  const hasWritePermission = permissions === "admin" || permissions === "staff";
  const hasUnassignedDeliveryContents = unassignedDeliveryContents?.some((e) => e.package_count > 0);
  const isExceptionFlow = amend?.is_exception_flow === true;
  const canUpdateDelivery =
    (isDeliveryCreationStep || isDeliveryInspectionStep) && hasWritePermission && !isExceptionFlow;
  const canCreateDelivery = canUpdateDelivery && hasUnassignedDeliveryContents;

  return (
    <Show
      {...props}
      actions={<SpotContractDealShowActions listPath="/spot_contract_deals" permissions={permissions} />}
    >
      <TabbedShowLayout>
        <Tab label="ui.deals.tabs.info">
          <TextField source="id" />
          <TextField source="offer_id" />
          <SelectField source="status" choices={createStatusChoices()} />
          <DateField source="decision_date" locales={"ja"} />
          <ProducerAgreementStatusField source="producer_agreement_status" showTime={true} />
          <WholesalerAgreementStatusField source="wholesaler_agreement_status" showTime={true} />
          <DateField source="agreement_deadline" locales={"ja"} />
          <TextField source="producer.company_name" />
          <InvoiceRegistrationStatusField source="producer.invoice_registration_status" />
          <DateField source="created_at" locales={"ja"} showTime={true} />
        </Tab>
        <Tab path="contract" label="ui.deals.tabs.contract">
          <TextField source="annum.name" />
          <TextField source="prefecture.name" />
          <TextField source="region.name" />
          <TextField source="brand.name" />
          <TextField source="grade.name" />
          <TextField source="packaging_form.name" />
          <NumberField source="trading_units" />
          <NumberField source="trading_sacks" />
          <NumberField source="trading_kg" />
          <NumberField source="package_count" />
          <NumberField source="unit_price" />
          <NumberField source="trading_volume_in_yen" />
          <NumberField source="unit_price_adjustment_in_yen" />
          <TextField source="storage_period.name" />
          <NumberField source="storage_period.bonus_in_yen" />
          {!isClosedOrDone && (
            <ArrayField source="grade_penalties">
              <Datagrid>
                <NumberField source="grade.name" />
                <NumberField source="penalty_in_yen" emptyText="受け取らない" />
              </Datagrid>
            </ArrayField>
          )}
          {isClosedOrDone && (
            <ReferenceField
              reference="spot_contract_amends"
              source="id"
              label="resources.spot_contract_amends.fields.grade_penalties"
              link={false}
            >
              <ArrayField source="grade_penalties">
                <Datagrid>
                  <NumberField source="grade.name" />
                  <NumberField source="penalty_in_yen" emptyText="受け取らない" />
                </Datagrid>
              </ArrayField>
            </ReferenceField>
          )}
          <ArrayField source="special_conditions">
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="special_conditions_memo" component="pre" />
        </Tab>
        <Tab path="offer" label="ui.deals.tabs.offer">
          <NumberField source="offer_unit_price" />
        </Tab>
        {isClosedOrDone && (
          <Tab path="delivery" label="ui.deals.tabs.delivery">
            {isExceptionFlow && (
              <Alert severity="warning">
                {translate("ui.deals.in_exception_flow")
                  .split("\n")
                  .map((line: string) => {
                    return <div key={line}>{line}</div>;
                  })}
              </Alert>
            )}
            <ReferenceField
              reference="spot_contract_amends"
              source="id"
              label="resources.spot_contract_amends.fields.handover_possible_date"
              link={false}
            >
              <DateField source="handover_possible_date" emptyText={translate("ui.value.not_set")} />
            </ReferenceField>
            <ReferenceField
              reference="spot_contract_amends"
              source="id"
              label="resources.spot_contract_amends.fields.handover_address"
              link={false}
            >
              <AddressField source="handover_address" />
            </ReferenceField>
            <ReferenceField
              reference="spot_contract_amends"
              source="id"
              label="resources.spot_contract_amends.fields.handover_memo"
              link={false}
            >
              <TextField source="handover_memo" component="pre" />
            </ReferenceField>
            <ReferenceField
              reference="spot_contract_amends"
              source="id"
              label="resources.spot_contract_amends.fields.inspection_results"
              link={false}
            >
              <ArrayField source="inspection_results">
                <Datagrid>
                  <TextField source="grade.name" emptyText={translate("ui.value.default_grade")} />
                  <NumberField source="package_count" />
                </Datagrid>
              </ArrayField>
            </ReferenceField>
            <ReferenceField
              reference="spot_contract_amends"
              source="id"
              label="resources.spot_contract_amends.fields.inspection_results_memo"
              link={false}
            >
              <TextField source="inspection_results_memo" component="pre" />
            </ReferenceField>
            <ReferenceField
              reference="spot_contract_amends"
              source="id"
              label="resources.spot_contract_amends.fields.inspection_results_attachments"
              link={false}
            >
              <ArrayField source="inspection_results_attachments">
                <SingleFieldList linkType={false}>
                  <AttachmentFileField
                    styles={{
                      width: "100%",
                      my: 1,
                    }}
                  />
                </SingleFieldList>
              </ArrayField>
            </ReferenceField>
            <h3>個別運送情報</h3>
            <ReferenceManyField
              reference="spot_contract_individual_deliveries"
              target="amend_id"
              label={false}
              pagination={<Pagination />}
            >
              <Datagrid expand={<SpotContractIndividualDeliveryShow />}>
                <TextField source="id" label={"resources.individual_deliveries.fields.id"} />
                <SelectField
                  source="delivery_type"
                  choices={createDeliveryTypeChoices()}
                  label="resources.individual_deliveries.fields.delivery_type"
                  sortable={false}
                />
                <SelectField
                  source="status"
                  choices={createDeliveryStatusChoices()}
                  label="resources.individual_deliveries.fields.status"
                  sortable={false}
                />
                <SelectField
                  source="has_producer_reviewed"
                  choices={createHasProducerReviewed()}
                  label="resources.individual_deliveries.fields.has_producer_reviewed"
                  sortable={false}
                />
                <ReferenceField
                  source="delivery_address.id"
                  label="resources.individual_deliveries.fields.delivery_address.id"
                  reference="wholesaler_addresses"
                  link={false}
                  sortable={false}
                >
                  <TextField source="name" />
                </ReferenceField>
                <HandoverAtField label="resources.individual_deliveries.fields.hacobell.handover_at" sortable={false} />
                <ReceiptTimingField label="resources.individual_deliveries.fields.receipt_timing" />
                {canUpdateDelivery && <EditDeliveryButton />}
                {canUpdateDelivery && <DeleteDeliveryButton />}
                {canUpdateDelivery && <InspectDeliveryButton />}
              </Datagrid>
            </ReferenceManyField>
            {canCreateDelivery && (
              <ReferenceField reference="spot_contract_amends" source="id" label="" link={false}>
                <CreateRelatedResourceButton
                  reference="spot_contract_individual_deliveries"
                  target="amend_id"
                  hasVersion={true}
                  permissions={permissions}
                />
              </ReferenceField>
            )}
          </Tab>
        )}
        {isClosedOrDone && (
          <Tab path="payment" label="ui.deals.tabs.payments">
            <TextField source="annum.name" />
            <TextField source="prefecture.name" />
            <TextField source="brand.name" />
            <ReferenceField
              reference="spot_contract_amends"
              source="id"
              label="resources.spot_contract_amends.fields.producer"
              link={false}
            >
              <TextField source="producer.company_name" />
            </ReferenceField>
            <InvoiceRegistrationStatusField source="producer.invoice_registration_status" />
            <ReferenceField
              reference="spot_contract_amends"
              source="id"
              label="resources.spot_contract_amends.fields.commission_rate"
              link={false}
            >
              <FunctionField
                source="commission_rate"
                render={getFormatRateString("commission_rate")}
                sortable={false}
              />
            </ReferenceField>
            <ReferenceManyField
              reference="spot_contract_payments"
              target="amend_id"
              label="resources.payments.name"
              pagination={<Pagination />}
            >
              <Datagrid
                expand={<SpotContractPaymentShow resource="spot_contract_payments" permissions={permissions} />}
              >
                <TextField source="id" label={"resources.payments.fields.id"} />
                <SelectField source="status" choices={createPaymentStatuses()} sortable={false} />
                <ReferenceField
                  source="individual_delivery_id"
                  reference="spot_contract_individual_deliveries"
                  label="resources.individual_deliveries.fields.handover_at"
                  link={false}
                  sortable={false}
                >
                  <HandoverAtField
                    label="resources.individual_deliveries.fields.hacobell.handover_at"
                    sortable={false}
                  />
                </ReferenceField>
                <ReferenceField
                  source="individual_delivery_id"
                  reference="spot_contract_individual_deliveries"
                  label="resources.individual_deliveries.fields.delivery_address"
                  link={false}
                  sortable={false}
                >
                  <ReferenceField
                    source="delivery_address_id"
                    reference="wholesaler_addresses"
                    link={false}
                    sortable={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </ReferenceField>
                <FunctionField
                  source="total_bill_in_yen"
                  render={getFormatNumeric("total_bill_in_yen")}
                  sortable={false}
                />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
