import React, { useCallback, useEffect, useState } from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  FormDataConsumer,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectField,
  SelectInput,
  ShowController,
  ShowView,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  maxValue,
  minValue,
  number,
  required,
  useDataProvider,
} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import { parse } from "query-string";
import { useTranslate } from "ra-core";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import { CheckedOnlyField } from "../components/checked_only_field";
import {
  createAdjustUnitPriceChoices,
  createIsDeletedChoices,
  createPublishStateChoices,
  createStateChoices,
} from "../components/choices";
import { CloneButton } from "../components/clone_button";
import { ExistsSpecialConditionsField } from "../components/exists_special_conditions_field";
import { GradePenaltyInput } from "../components/grade_penalty_input";
import { HiddenInput } from "../components/hidden";
import { SpotContractOfferShowActions } from "../components/SpotContractOfferActions";
import { SpotContractOfferPublicationPeriodInput } from "../components/SpotContractOfferPublicationPeriodInput";
import { SpotContractStoragePeriodsField } from "../components/SpotContractStoragePeriodsField";
import { RelationalSelectInput } from "../components/relational_select_input";

import {
  highestTradingUnits,
  likeInteger,
  steppedPrice,
  steppedStringPrice,
  lowestTradingUnits,
  notSameGrade,
  validGradePenaltyPrice,
  validPublicationPeriod,
  validPackagingFormDifference,
} from "../utils/offer_validator";
import { preventSendNull } from "../utils/api";

import { FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS, INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS } from "../utils/parameters";
import { PrefecturesSelectField } from "../components/prefectures_select_field";
import { BrandSelectInput } from "../components/brand_select_input";
import { Holiday } from "../infrastructure/types";
import { validateMaxLengthText } from "../utils/validator";
import { PackagingForm } from "../api/amend";
import { OmitReferenceArrayField } from "../components/omit_reference_array_field";

type Region = {
  id: number;
  is_active: boolean;
  name: string;
  prefecture_id: number;
};

export type PublicationPeriod = {
  id: number;
  is_active: boolean;
  is_deleted: boolean;
  name: string;
  publish_end_date: string;
  publish_start_date: string;
};

const useStyles = makeStyles({
  progressWrapper: {
    alignItems: "center",
    display: "flex",
    height: 480,
    justifyContent: "center",
  },
});

const SpotContractOfferFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" min={1} />
    <SelectInput source="is_deleted" choices={createIsDeletedChoices()} />
    <SelectInput source="is_active" choices={createStateChoices()} />
    <SelectInput source="publish_status" choices={createPublishStateChoices()} />
    <TextInput source="offer_publication_period_name" />
    <TextInput source="annum_name" />
    <TextInput source="prefecture_name" />
    <TextInput source="brand_name" />
    <TextInput source="grade_name" />
    <SelectInput source="can_adjust_unit_price" choices={createAdjustUnitPriceChoices()} />
  </Filter>
);

const omitKeysForCloneButton = [
  "created_at",
  "created_by_account",
  "id",
  "is_active",
  "is_deleted",
  "publish_end_date",
  "publish_start_date",
  "publish_status",
  "trading_units_closed",
  "trading_units_left",
  "trading_units_provisional",
  "updated_at",
  "updated_by_account",
  "version_number",
];

export const SpotContractOfferList = (props: any) => {
  const { permissions } = props;
  const disableCopyButton = !["admin", "staff"].includes(permissions);

  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [validValues, setValidValues] = useState<{ [key: string]: any }>({});
  const classes = useStyles();

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      if (!isCleanedUp) {
        setIsLoading(true);
      }

      const fetchedAnnums = await dataProvider.getList("spot_contract_annums", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);
      const fetchedBrands = await dataProvider.getList("brands", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);
      const fetchedPrefectures = await dataProvider.getList("prefectures", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);
      const fetchedRegions = await dataProvider.getList("regions", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);
      const fetchedPackagingForms = await dataProvider.getList("packaging_forms", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);
      const fetchedStoragePeriods = await dataProvider.getList(
        "spot_contract_storage_periods",
        FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS
      );
      const fetchedSpecialConditions = await dataProvider.getList(
        "spot_contract_special_conditions",
        FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS
      );

      if (!isCleanedUp) {
        // fetchに失敗すると結果がundefeindになるため
        if (
          fetchedAnnums &&
          fetchedBrands &&
          fetchedPrefectures &&
          fetchedRegions &&
          fetchedPackagingForms &&
          fetchedStoragePeriods &&
          fetchedSpecialConditions
        ) {
          setValidValues({
            annum_id: fetchedAnnums.data,
            prefecture_ids: fetchedPrefectures.data,
            brand_id: fetchedBrands.data,
            region_id: fetchedRegions.data,
            packaging_form_ids: fetchedPackagingForms.data,
            storage_period_ids: fetchedStoragePeriods.data,
            special_condition_ids: fetchedSpecialConditions.data,
          });
        }

        setIsLoading(false);
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider]);

  if (isLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <List
      {...props}
      filters={<SpotContractOfferFilter />}
      filterDefaultValues={{ is_deleted: false, is_active: true }}
      sort={{ field: "id", order: "DESC" }}
      bulkActionButtons={false}
      exporter={false}
    >
      <CustomizableDatagrid rowClick="show">
        <TextField source="id" />
        <CheckedOnlyField source="is_deleted" sortable={false} />
        <SelectField source="is_active" choices={createStateChoices()} sortable={false} />
        <SelectField source="publish_status" choices={createPublishStateChoices()} sortable={false} />
        <ReferenceField
          source="offer_publication_period_id"
          reference="spot_contract_offer_publication_periods"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="publish_start_date" locales={"ja"} sortable={false} />
        <DateField source="publish_end_date" locales={"ja"} sortable={false} />
        <ReferenceField source="annum_id" reference="spot_contract_annums" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <PrefecturesSelectField source="prefecture_ids" reference="prefectures" />
        <ReferenceField source="brand_id" reference="brands" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="grade_id" reference="grades" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="region_id" reference="regions" link={false} sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="unit_price" sortable={false} />
        <NumberField source="total_trading_units" sortable={false} />
        <NumberField source="trading_units_closed" sortable={false} />
        <OmitReferenceArrayField source="packaging_form_ids" reference="packaging_forms" sortable={false} />
        <SelectField source="can_adjust_unit_price" choices={createAdjustUnitPriceChoices()} sortable={false} />
        <SpotContractStoragePeriodsField source="storage_period_ids" sortable={false} />
        <ExistsSpecialConditionsField source="special_condition_ids" sortable={false} />
        <CloneButton omitKeys={omitKeysForCloneButton} disabled={disableCopyButton} validValues={validValues} />
      </CustomizableDatagrid>
    </List>
  );
};

export const SpotContractOfferShow = (props: any) => {
  const { permissions } = props;

  return (
    <ShowController {...props}>
      {(controllerProps: any) => (
        <ShowView
          {...props}
          {...controllerProps}
          actions={<SpotContractOfferShowActions listPath="/spot_contract_offers" permissions={permissions} />}
        >
          <SimpleShowLayout>
            <TextField source="id" />
            {controllerProps.record && controllerProps.record.is_deleted && <BooleanField source="is_deleted" />}
            <SelectField source="is_active" choices={createStateChoices()} />
            <SelectField source="publish_status" choices={createPublishStateChoices()} />
            <ReferenceField
              source="offer_publication_period_id"
              reference="spot_contract_offer_publication_periods"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <DateField source="publish_start_date" locales={"ja"} />
            <DateField source="publish_end_date" locales={"ja"} />
            <ReferenceField source="annum_id" reference="spot_contract_annums" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField source="prefecture_ids" reference="prefectures">
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceField source="brand_id" reference="brands" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="grade_id" reference="grades" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="region_id" reference="regions" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <NumberField source="unit_price" />
            <NumberField source="lowest_trading_units" />
            <NumberField source="highest_trading_units" />
            <NumberField source="total_trading_units" />
            <ReferenceArrayField source="packaging_form_ids" reference="packaging_forms">
              <Datagrid>
                <TextField source="name" sortable={false} label="resources.offers.fields.packaging_form_ids.name" />
                <NumberField
                  source="deal_amount"
                  sortable={false}
                  label="resources.offers.fields.packaging_form_ids.deal_amount"
                />
              </Datagrid>
            </ReferenceArrayField>
            <SelectField source="can_adjust_unit_price" choices={createAdjustUnitPriceChoices()} />
            <ReferenceArrayField source="storage_period_ids" reference="spot_contract_storage_periods" linkType={false}>
              <Datagrid>
                <TextField label="resources.spot_contract_storage_periods.fields.name" source="name" />
                <NumberField
                  label="resources.spot_contract_storage_periods.fields.storage_period_in_days"
                  source="storage_period_in_days"
                />
                <NumberField
                  label="resources.spot_contract_storage_periods.fields.bonus_in_yen"
                  source="bonus_in_yen"
                />
              </Datagrid>
            </ReferenceArrayField>
            <ArrayField source="grade_penalties">
              <Datagrid>
                <ReferenceField source="grade_id" reference="grades" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <NumberField source="penalty_in_yen" emptyText={"受け取らない"} />
              </Datagrid>
            </ArrayField>
            <NumberField source="trading_units_closed" />
            <NumberField source="trading_units_provisional" />
            <NumberField source="trading_units_left" />
            <ReferenceArrayField
              source="special_condition_ids"
              reference="spot_contract_special_conditions"
              className="array-field-that-potential-empty"
            >
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
            {controllerProps.record?.special_condition_ids !== undefined &&
              controllerProps.record.special_condition_ids.length !== 0 && (
                <TextField source="special_conditions_memo" component="pre" />
              )}
            <TextField source="created_by_account.name" />
            <DateField source="created_at" locales={"ja"} showTime={true} />
            <TextField source="updated_by_account.name" />
            <DateField source="updated_at" locales={"ja"} showTime={true} />
          </SimpleShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

const validateRequired = [required()];
const validateUnitPrice = [required(), number(), minValue(5000), maxValue(50000), steppedPrice];
const validateLowestUnits = [number(), minValue(1), maxValue(999), lowestTradingUnits];
const validateHighestUnits = [number(), minValue(1), maxValue(999), highestTradingUnits];
const validateTotalUnits = [required(), number(), minValue(1), maxValue(999)];
const validateGradePenaltyGrade = [required(), notSameGrade];
const validateGradePenaltyPrice = [
  minValue(-5000),
  maxValue(0),
  likeInteger,
  steppedStringPrice,
  validGradePenaltyPrice,
];
const validateSpecialConditionsMemo = validateMaxLengthText(2048);

const createRegionChoices = (regions: Region[], prefectureIds: number[]) => {
  const _prefectureIds = prefectureIds ? prefectureIds : [];
  if (_prefectureIds.length !== 1) {
    return [];
  }

  const prefectureId = _prefectureIds[0];
  return regions
    .filter((region) => region.prefecture_id === prefectureId)
    .map((region) => ({ id: region.id, name: region.name }));
};

type ImmutableMasterItem = {
  id: number;
  name: string;
  is_active: boolean;
};

const transform = (data: any) => {
  const { highest_trading_units, lowest_trading_units, total_trading_units } = data;
  return {
    ...data,
    highest_trading_units: highest_trading_units ? highest_trading_units : total_trading_units,
    lowest_trading_units: lowest_trading_units ? lowest_trading_units : 1,
  };
};

const SpotContractOfferCreateToolbar = (props: any) => {
  // HACK
  // Toolbar経由でSaveButtonにpristineが渡されるので、ここでpristineを差し替える。
  // 実装上は`pristine === true`でFormに変更なしという判定をしている。
  // SaveButton内部では`disabled || pristine`でdisabledを設定しているので、
  // `alwaysEnableSaveButton === true`の場合は常にfalseを返すようにして、
  // コピーして作成の場合も擬似的に変更があると見せかけて無条件に保存が押せるようにしている。
  const pristine = !props.alwaysEnableSaveButton && props.pristine;

  return (
    <Toolbar {...props} pristine={pristine}>
      <SaveButton transform={transform} submitOnEnter={false} />
    </Toolbar>
  );
};

const initialOfferValues = {
  prefecture_ids: [],
  region_id: "",
  grade_penalties: [],
  special_condition_ids: [],
  special_conditions_memo: "",
};

export const SpotContractOfferCreate = (props: any) => {
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [regions, setRegions] = useState<Region[]>([]);
  const [publicationPeriods, setPublicationPeriods] = useState<PublicationPeriod[]>([]);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [initialStoragePeriodId, setInitialStoragePeriodId] = useState<number[]>([]);
  const [packagingForms, setPackagingForms] = useState<PackagingForm[]>([]);

  const classes = useStyles();
  const translate = useTranslate();

  // 複製から遷移した場合は変更がなくても保存ボタンを押せるようにする
  const alwaysEnableSaveButton = parse(props.location.search)["source"] !== undefined;
  const loadingClone = parse(props.location.search)["source"] !== undefined;

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      if (!isCleanedUp) {
        setIsLoading(true);
      }

      const fetchedRegions = await dataProvider.getList("regions", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);
      const fetchedStoragePeriods = await dataProvider.getList(
        "spot_contract_storage_periods",
        FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS
      );
      const fetchedHolidays = await dataProvider.getList("holidays", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);
      const fetchedPackagingForms = await dataProvider.getList("packaging_forms", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);

      const periodParams = {
        ...FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS,
        filter: {
          ...FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS.filter,
          is_deleted: false,
          is_selectable: true,
        },
      };
      const fetchedOfferPublicationPeriods = await dataProvider.getList(
        "spot_contract_offer_publication_periods",
        periodParams
      );

      if (!isCleanedUp) {
        // fetchに失敗すると結果がundefeindになるため
        if (fetchedRegions && fetchedStoragePeriods && fetchedOfferPublicationPeriods && fetchedPackagingForms) {
          setRegions(fetchedRegions.data);
          setHolidays(fetchedHolidays.data);
          setPackagingForms(fetchedPackagingForms.data);

          const ids = fetchedStoragePeriods.data
            .filter((item: ImmutableMasterItem) => item.is_active && item.name.startsWith("標準"))
            .map((item: ImmutableMasterItem) => item.id);
          setInitialStoragePeriodId(ids);

          const items = fetchedOfferPublicationPeriods.data.filter(
            (item: PublicationPeriod) => item.is_active && !item.is_deleted
          );
          setPublicationPeriods(items);
        }

        setIsLoading(false);
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider]);

  const onChangeStoragePeriods = useCallback(
    (ids: number[]) => {
      const nextIds = ids.concat(initialStoragePeriodId);
      return Array.from(new Set(nextIds));
    },
    [initialStoragePeriodId]
  );

  if (isLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const now = new Date();
  const validateOfferPublicationPeriod = [required(), validPublicationPeriod(publicationPeriods, now, holidays)];
  const validatePackagingForms = [required(), validPackagingFormDifference(packagingForms)];

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={initialOfferValues}
        toolbar={<SpotContractOfferCreateToolbar alwaysEnableSaveButton={alwaysEnableSaveButton} />}
        redirect="show"
      >
        <HiddenInput source="is_active" value={true} />
        {loadingClone && (
          <FormDataConsumer>
            {(formProps: any) => {
              const { formData } = formProps;
              const message =
                formData.invalidKeys && formData.invalidKeys.length > 0
                  ? "複製の際に無効なデータをクリアしています"
                  : null;

              return (
                message && (
                  <Typography color="error" variant="body1">
                    <p>{message}</p>
                    <ul>
                      {formData.invalidKeys.map((key: string) => {
                        return <li key={key}>{translate(`resources.offers.fields.${key}`)}</li>;
                      })}
                    </ul>
                  </Typography>
                )
              );
            }}
          </FormDataConsumer>
        )}
        <SpotContractOfferPublicationPeriodInput
          source="offer_publication_period_id"
          validate={validateOfferPublicationPeriod}
          {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}
        />
        <ReferenceInput source="annum_id" reference="spot_contract_annums" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
          <SelectInput optionText="name" validate={validateRequired} />
        </ReferenceInput>
        <ReferenceArrayInput source="prefecture_ids" reference="prefectures" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
          <SelectArrayInput optionText="name" validate={validateRequired} />
        </ReferenceArrayInput>
        <BrandSelectInput source="brand_id" reference="brands" validate={validateRequired} />
        <ReferenceInput source="grade_id" reference="grades" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
          <SelectInput optionText="name" validate={validateRequired} />
        </ReferenceInput>
        <FormDataConsumer>
          {(formProps: any) => {
            const { formData, ...rest } = formProps;
            const choices = createRegionChoices(regions, formData.prefecture_ids);
            return (
              <RelationalSelectInput
                source="region_id"
                selectedId={formData.region_id}
                groupId={formData.prefecture_id}
                choices={choices}
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
        <NumberInput source="unit_price" validate={validateUnitPrice} />
        <NumberInput source="lowest_trading_units" validate={validateLowestUnits} />
        <NumberInput source="highest_trading_units" validate={validateHighestUnits} />
        <NumberInput source="total_trading_units" validate={validateTotalUnits} />
        <ReferenceArrayInput
          source="packaging_form_ids"
          reference="packaging_forms"
          {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}
        >
          <SelectArrayInput optionText="name" validate={validatePackagingForms} />
        </ReferenceArrayInput>
        <SelectInput
          source="can_adjust_unit_price"
          choices={createAdjustUnitPriceChoices()}
          validate={validateRequired}
          helperText="ui.offers.can_adjust_unit_price_helper_text"
        />
        <ReferenceArrayInput
          source="storage_period_ids"
          reference="spot_contract_storage_periods"
          initialValue={initialStoragePeriodId}
          parse={onChangeStoragePeriods}
          helperText="標準は選択から外すことができません"
          {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}
        >
          <SelectArrayInput optionText="name" validate={validateRequired} />
        </ReferenceArrayInput>
        <ArrayInput source="grade_penalties">
          <SimpleFormIterator disableReordering={true}>
            <ReferenceInput source="grade_id" reference="grades" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
              <SelectInput optionText="name" validate={validateGradePenaltyGrade} />
            </ReferenceInput>
            <GradePenaltyInput source="penalty_in_yen" initialValue="" validate={validateGradePenaltyPrice} />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput
          source="special_condition_ids"
          reference="spot_contract_special_conditions"
          {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}
          fullWidth
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <FormDataConsumer>
          {({ formData }: { formData: any }) => {
            if (!formData.special_condition_ids || formData.special_condition_ids.length === 0) {
              return null;
            }
            return (
              <TextInput
                source="special_conditions_memo"
                label="resources.offers.fields.special_conditions_memo"
                parse={preventSendNull}
                validate={validateSpecialConditionsMemo}
                fullWidth
                multiline
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export const SpotContractOfferEdit = (props: any) => {
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [regions, setRegions] = useState<Region[]>([]);
  const [initialStoragePeriodId, setInitialStoragePeriodId] = useState<number[]>([]);
  const [publicationPeriods, setPublicationPeriods] = useState<PublicationPeriod[]>([]);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [packagingForms, setPackagingForms] = useState<PackagingForm[]>([]);

  const classes = useStyles();

  useEffect(() => {
    let isCleanedUp = false;

    (async () => {
      if (!isCleanedUp) {
        setIsLoading(true);
      }

      const fetchedRegions = await dataProvider.getList("regions", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);
      const fetchedStoragePeriods = await dataProvider.getList(
        "spot_contract_storage_periods",
        FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS
      );
      const fetchedHolidays = await dataProvider.getList("holidays", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);
      const fetchedPackagingForms = await dataProvider.getList("packaging_forms", FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS);

      const periodParams = {
        ...FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS,
        filter: {
          ...FETCH_PARAMS_FOR_ALL_ACTIVE_ITEMS.filter,
          is_deleted: false,
        },
      };
      const fetchedOfferPublicationPeriods = await dataProvider.getList(
        "spot_contract_offer_publication_periods",
        periodParams
      );

      if (!isCleanedUp) {
        // fetchに失敗すると結果がundefeindになるため
        if (fetchedRegions && fetchedStoragePeriods && fetchedOfferPublicationPeriods && fetchedPackagingForms) {
          setRegions(fetchedRegions.data);
          setHolidays(fetchedHolidays.data);
          setPackagingForms(fetchedPackagingForms.data);

          const ids = fetchedStoragePeriods.data
            .filter((item: ImmutableMasterItem) => item.is_active && item.name.startsWith("標準"))
            .map((item: ImmutableMasterItem) => item.id);
          setInitialStoragePeriodId(ids);

          const items = fetchedOfferPublicationPeriods.data.filter(
            (item: PublicationPeriod) => item.is_active && !item.is_deleted
          );
          setPublicationPeriods(items);
        }

        setIsLoading(false);
      }
    })();

    return () => {
      isCleanedUp = true;
    };
  }, [dataProvider]);

  const onChangeStoragePeriods = useCallback(
    (ids: number[]) => {
      const nextIds = ids.concat(initialStoragePeriodId);
      return Array.from(new Set(nextIds));
    },
    [initialStoragePeriodId]
  );

  if (isLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const now = new Date();
  const validateOfferPublicationPeriod = [required(), validPublicationPeriod(publicationPeriods, now, holidays)];
  const validatePackagingForms = [required(), validPackagingFormDifference(packagingForms)];

  // HACK: undoable=falseにしないとhooksのdataProviderがデータ取得でundefinedを返してエラーになる
  return (
    <Edit {...props} transform={transform} undoable={false}>
      <SimpleForm redirect="show">
        <TextField source="id" />
        <SelectField source="is_active" choices={createStateChoices()} validate={validateRequired} />
        <HiddenInput source="version_number" />
        <SpotContractOfferPublicationPeriodInput
          source="offer_publication_period_id"
          validate={validateOfferPublicationPeriod}
          {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}
        />
        <ReferenceInput source="annum_id" reference="spot_contract_annums" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
          <SelectInput optionText="name" validate={validateRequired} />
        </ReferenceInput>
        <ReferenceArrayInput source="prefecture_ids" reference="prefectures" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
          <SelectArrayInput optionText="name" validate={validateRequired} />
        </ReferenceArrayInput>
        <BrandSelectInput source="brand_id" reference="brands" validate={validateRequired} />
        <ReferenceInput source="grade_id" reference="grades" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
          <SelectInput optionText="name" validate={validateRequired} />
        </ReferenceInput>
        <FormDataConsumer>
          {(formProps: any) => {
            const { formData, ...rest } = formProps;
            const choices = createRegionChoices(regions, formData.prefecture_ids);
            return (
              <RelationalSelectInput
                source="region_id"
                selectedId={formData.region_id}
                groupId={formData.prefecture_id}
                choices={choices}
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
        <NumberInput source="unit_price" validate={validateUnitPrice} />
        <NumberInput source="lowest_trading_units" validate={validateLowestUnits} />
        <NumberInput source="highest_trading_units" validate={validateHighestUnits} />
        <NumberInput source="total_trading_units" validate={validateTotalUnits} />
        <ReferenceArrayInput
          source="packaging_form_ids"
          reference="packaging_forms"
          {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}
        >
          <SelectArrayInput optionText="name" validate={validatePackagingForms} />
        </ReferenceArrayInput>
        <SelectInput
          source="can_adjust_unit_price"
          choices={createAdjustUnitPriceChoices()}
          validate={validateRequired}
          helperText="ui.offers.can_adjust_unit_price_helper_text"
        />
        <ReferenceArrayInput
          source="storage_period_ids"
          reference="spot_contract_storage_periods"
          parse={onChangeStoragePeriods}
          {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}
        >
          <SelectArrayInput optionText="name" validate={validateRequired} />
        </ReferenceArrayInput>
        <ArrayInput source="grade_penalties">
          <SimpleFormIterator disableReordering={true}>
            <ReferenceInput source="grade_id" reference="grades" {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}>
              <SelectInput optionText="name" validate={validateGradePenaltyGrade} />
            </ReferenceInput>
            <GradePenaltyInput source="penalty_in_yen" initialValue="" validate={validateGradePenaltyPrice} />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput
          source="special_condition_ids"
          reference="spot_contract_special_conditions"
          {...INPUT_PARAMS_FOR_ALL_ACTIVE_ITEMS}
          fullWidth
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <FormDataConsumer>
          {({ formData }: { formData: any }) => {
            if (!formData.special_condition_ids || formData.special_condition_ids.length === 0) {
              return null;
            }
            return (
              <TextInput
                source="special_conditions_memo"
                label="resources.offers.fields.special_conditions_memo"
                parse={preventSendNull}
                validate={validateSpecialConditionsMemo}
                fullWidth
                multiline
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
